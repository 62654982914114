
import { defineComponent } from "vue-demi";
import MainMain from "@/components/main/main/index.vue";

export default defineComponent({
  name: "MainView",
  components: {
    MainMain,
  },
});
