import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b4805b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main flex direction-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_header = _resolveComponent("main-header")!
  const _component_main_program = _resolveComponent("main-program")!
  const _component_main_leader = _resolveComponent("main-leader")!
  const _component_main_about = _resolveComponent("main-about")!
  const _component_main_partner = _resolveComponent("main-partner")!
  const _component_main_promo = _resolveComponent("main-promo")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_main_header),
    _createVNode(_component_main_program, { user: _ctx.user }, null, 8, ["user"]),
    _createVNode(_component_main_leader),
    _createVNode(_component_main_about),
    _createVNode(_component_main_partner),
    _createVNode(_component_main_promo)
  ]))
}