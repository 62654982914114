
import { defineComponent } from "vue-demi";
import { StarIcon } from "@/plugins/icons";
import { differenceTime } from "@/utils/calendar";

export default defineComponent({
  name: "MainLeader",
  data() {
    return {
      startProject: new Date("01/01/2023"),
    };
  },
  methods: {
    differenceTime,
  },
  components: {
    StarIcon,
  },
});
