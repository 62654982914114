
import { defineComponent, PropType } from "vue-demi";
import { Banner } from "@/interfaces/banner/banner.dto";

export default defineComponent({
  name: 'MainPromoBanner',
  props: {
    banner: {
      type: Object as PropType<Banner.Dto>,
      required: true
    }
  },
  methods: {
    openBanner() {
      console.log(this.banner);
    }
  }
})
