
import { defineComponent } from "vue-demi";
import { main } from '@/locales/ru/common.json';

export default defineComponent({
  name: 'MainProgram',
  props: {
    user: Object
  },
  data() {
    return {
      locates: main
    }
  },
  methods: {
    route(route: string) {
      this.$router.push(route);
    }
  }
})
