
import { defineComponent } from "vue-demi";
import { useAPI } from "@/use";
import { Banner } from '@/interfaces/banner/banner.dto';
import { DownArrowIcon } from '@/plugins/icons';
import CommonBanner from './Banner.vue';

export default defineComponent({
  name: 'MainPromo',
  data() {
    return {
      banners: [] as Array<Banner.Dto>,
    }
  },
  created() {
    this.getBannerListMethod();
  },
  methods: {
    async getBannerListMethod() {
      const result = await useAPI().banner.getListMethod();
      this.banners = result.data;
    },
    scrollBanners(inc: number) {
      const container = this.jq('.promo--container');
      container.scrollLeft((container[0].scrollLeft || 0) + (400 * inc));
    },
  },
  components: {
    CommonBanner,
    DownArrowIcon
  }
})
