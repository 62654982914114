
import { defineComponent } from "vue-demi";
import { mapGetters } from "vuex";
import MainHeader from './modules/header/index.vue';
import MainProgram from './modules/program/index.vue';
import MainLeader from './modules/leader/index.vue';
import MainAbout from './modules/about/index.vue';
import MainPartner from './modules/partner/index.vue';
import MainPromo from './modules/promo/index.vue';

export default defineComponent({
  name: 'Main',
  computed: {
    ...mapGetters(['user']),
  },
  components: {
    MainHeader,
    MainProgram,
    MainLeader,
    MainAbout,
    MainPartner,
    MainPromo,
  }
})
