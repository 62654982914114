
import { defineComponent } from "vue-demi";
import { main, contacts } from '@/locales/ru/common.json';
import { ScrollDownIcon } from '@/plugins/icons';

export default defineComponent({
  name: 'MainHeader',
  data() {
    return {
      locates: main
    }
  },
  methods: {
    route() {
      this.$router.push('/catalog');
    },
    scroll() {
      const program = this.jq('.program');
      this.jq('html, body').stop().animate({
        scrollTop: (program.offset()?.top || 100) - 120
      }, 500);
    }
  },
  components: {
    ScrollDownIcon,
  }
})
