
import { defineComponent } from "vue-demi";
import { mapGetters } from "vuex";
import { main } from '@/locales/ru/common.json';
import { User } from "@/interfaces/user/user.dto";
import { ROLES } from "@/utils/enums";

export default defineComponent({
  name: 'MainPartner',
  data() {
    return {
      locates: main
    }
  },
  methods: {
    partnerAction() {
      if (this.user) {
        this.$router.push('/profile');
      } else {
        this.$router.push('/registration?partner=partner');
      }
    }
  },
  computed: {
    ...mapGetters(['user']),
    isShow() {
      const user = this.user as User.Dto;
      return !user || user?.role === ROLES.USER;
    }
  }
})
