import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d8699ffc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "banner--image" }
const _hoisted_2 = {
  key: 0,
  alt: ""
}
const _hoisted_3 = { class: "banner--container" }
const _hoisted_4 = { class: "name alsb fz20" }
const _hoisted_5 = { class: "description als fz18 text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", {
    class: "banner cursor",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openBanner && _ctx.openBanner(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.banner.image)
        ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_2, null, 512)), [
            [_directive_lazy, _ctx.banner.image.src]
          ])
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.banner.name), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.banner.description), 1)
    ])
  ]))
}